
// import {WOW} from '../../../node_modules/wowjs/dist/wow';
import _, { conforms } from 'lodash'
// new WOW().init();


/**
 * 搜索框显示用了ajax动态获取，为方便修改，写到了模板footer中
 */

//搜索提示框显示
// $('input.search-input').on('input',_.debounce(function(){
//   $(this).parents('.common-search-container').find('.common-search-hint').addClass('show')
//   if($(this).val() == ''){
//     $(this).parents('.common-search-container').find('.common-search-hint').removeClass('show')
//   }
// },500))

//搜索提示框隐藏
$('.common-search-hint .close-btn').on('click',function(){
  $(this).parents('.common-search-hint').removeClass('show')
  $(this).parents('.common-search-container').find('input.search-input').val('')
})

$(document).ready(function(){
  setlatestSpanW()
})

function setlatestSpanW(){
  if($('.common-latest-news-list').length > 0){
    let listEl = $('.common-latest-news-list')
    for(let i=0;i<listEl.length; i++){
      let spanEl = $(listEl[i]).find('li span')
      let arr = []
      for(let j=0; j<spanEl.length;j++){
        arr.push($(spanEl[j]).outerWidth())
      }
      let maxSpanW = _.max(arr)
      console.log(maxSpanW)
      $(listEl[i]).find('li span').css('width',maxSpanW+'px')
    }
  }
}

