import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'

if($('.print-swiper').length > 0){
  var swiper = new Swiper(".print-swiper .mySwiper", {
    slidesPerView: 1,
    spaceBetween: 10,
    navigation: {
      nextEl: ".print-swiper .swiper-button-next",
      prevEl: ".print-swiper .swiper-button-prev",
    },
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 50,
      },
      1440: {
        slidesPerView: 3,
        spaceBetween: 60,
      },
      1600: {
        slidesPerView: 3,
        spaceBetween: 100,
      },
    },
  });
}