import './index.scss'

// if($('.common-list').length > 0){
//   // 查看更多
//   if($(window).width() > 992){
//     var cmListinitshowliNum = 3
//     var cmListshowstep = 3
//   }else{
//     var cmListinitshowliNum = 2
//     var cmListshowstep = 2
//   }
//   var cmListclickNum = 0
//   var cmListtotalli = $('.common-list ul li').length

//   if (cmListtotalli <= 3 && $(window).width() > 992 || cmListtotalli <= 2 && $(window).width() <= 992) {
//       $('.common-list .common-more-btn').hide()
//   }

//   $('.common-list .common-more-btn').on('click', function () {
//     cmListclickNum += 1
//       if (cmListinitshowliNum + cmListshowstep * cmListclickNum < cmListtotalli) {
//           for (let i = 0; i < cmListshowstep; i++) {
//               $('.common-list ul li').eq(cmListinitshowliNum + (cmListclickNum - 1) * cmListshowstep + i).show()
//           }
//       } else {
//           for (let i = 0; i < cmListtotalli - cmListclickNum * cmListshowstep; i++) {
//               $('.common-list ul li').eq(cmListinitshowliNum + (cmListclickNum - 1) * cmListshowstep + i).show()
//           }
//           $('.common-list .common-more-btn').hide()
//       }
//       resizeScroll()
//   })
// }