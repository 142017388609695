module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<section class="print-section common-pd">\r\n  <div class="container">\r\n    <div class="cont">\r\n      <h1 class="ny-common-title">出版物</h1>\r\n      <div class="print-swiper">\r\n        <div class="swiper mySwiper">\r\n          <div class="swiper-wrapper">\r\n            <div class="swiper-slide">\r\n              <a href="#" class="item-img haszw-img">\r\n                <!-- 占位图片 -->\r\n                <img src="' +
((__t = (require('./images/print_zhanwei.jpg'))) == null ? '' : __t) +
'" alt="">\r\n                <!-- 缩略图 -->\r\n                <b style="background-image: url(' +
((__t = (require('./images/print_img1.jpg'))) == null ? '' : __t) +
');"></b>\r\n                <div class="img-mask"><span>点击预览</span></div>\r\n              </a>\r\n              <div class="item-text"><a href="#">文康私塾-开庭篇</a></div>\r\n              <div class="item-icon">\r\n                <a href="#">\r\n                  <i class="iconfont icon-xiazai"></i>\r\n                  <span>下载</span>\r\n                  <em>52页 (2.57MB)</em>\r\n                </a>\r\n              </div>\r\n            </div>\r\n            <div class="swiper-slide">\r\n              <a href="#" class="item-img haszw-img">\r\n                <!-- 占位图片 -->\r\n                <img src="' +
((__t = (require('./images/print_zhanwei.jpg'))) == null ? '' : __t) +
'" alt="">\r\n                <!-- 缩略图 -->\r\n                <b style="background-image: url(' +
((__t = (require('./images/print_img1.jpg'))) == null ? '' : __t) +
');"></b>\r\n                <div class="img-mask"><span>点击预览</span></div>\r\n              </a>\r\n              <div class="item-text"><a href="#">文康私塾-开庭篇</a></div>\r\n              <div class="item-icon">\r\n                <a href="#">\r\n                  <i class="iconfont icon-xiazai"></i>\r\n                  <span>下载</span>\r\n                  <em>52页 (2.57MB)</em>\r\n                </a>\r\n              </div>\r\n            </div>\r\n            <div class="swiper-slide">\r\n              <a href="#" class="item-img haszw-img">\r\n                <!-- 占位图片 -->\r\n                <img src="' +
((__t = (require('./images/print_zhanwei.jpg'))) == null ? '' : __t) +
'" alt="">\r\n                <!-- 缩略图 -->\r\n                <b style="background-image: url(' +
((__t = (require('./images/print_img1.jpg'))) == null ? '' : __t) +
');"></b>\r\n                <div class="img-mask"><span>点击预览</span></div>\r\n              </a>\r\n              <div class="item-text"><a href="#">文康私塾-开庭篇</a></div>\r\n              <div class="item-icon">\r\n                <a href="#">\r\n                  <i class="iconfont icon-xiazai"></i>\r\n                  <span>下载</span>\r\n                  <em>52页 (2.57MB)</em>\r\n                </a>\r\n              </div>\r\n            </div>\r\n            <div class="swiper-slide">\r\n              <a href="#" class="item-img haszw-img">\r\n                <!-- 占位图片 -->\r\n                <img src="' +
((__t = (require('./images/print_zhanwei.jpg'))) == null ? '' : __t) +
'" alt="">\r\n                <!-- 缩略图 -->\r\n                <b style="background-image: url(' +
((__t = (require('./images/print_img1.jpg'))) == null ? '' : __t) +
');"></b>\r\n                <div class="img-mask"><span>点击预览</span></div>\r\n              </a>\r\n              <div class="item-text"><a href="#">文康私塾-开庭篇</a></div>\r\n              <div class="item-icon">\r\n                <a href="#">\r\n                  <i class="iconfont icon-xiazai"></i>\r\n                  <span>下载</span>\r\n                  <em>52页 (2.57MB)</em>\r\n                </a>\r\n              </div>\r\n            </div>\r\n            <div class="swiper-slide">\r\n              <a href="#" class="item-img haszw-img">\r\n                <!-- 占位图片 -->\r\n                <img src="' +
((__t = (require('./images/print_zhanwei.jpg'))) == null ? '' : __t) +
'" alt="">\r\n                <!-- 缩略图 -->\r\n                <b style="background-image: url(' +
((__t = (require('./images/print_img1.jpg'))) == null ? '' : __t) +
');"></b>\r\n                <div class="img-mask"><span>点击预览</span></div>\r\n              </a>\r\n              <div class="item-text"><a href="#">文康私塾-开庭篇</a></div>\r\n              <div class="item-icon">\r\n                <a href="#">\r\n                  <i class="iconfont icon-xiazai"></i>\r\n                  <span>下载</span>\r\n                  <em>52页 (2.57MB)</em>\r\n                </a>\r\n              </div>\r\n            </div>\r\n          </div>\r\n        </div>\r\n        <div class="swiper-button-next">\r\n          <div><i class="iconfont icon-arrow-right"></i><i class="iconfont icon-arrow-right"></i></div>\r\n        </div>\r\n        <div class="swiper-button-prev">\r\n          <div><i class="iconfont icon-arrow-left"></i><i class="iconfont icon-arrow-left"></i></div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</section>';

}
return __p
}