module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!bannerPath) var bannerPath='';
if (!cntitle) var cntitle = '';
if (!entitle) var entitle = '';
;
__p += '\r\n<section class="law-observe-banner">\r\n  <div class="banner-mask"></div>\r\n  <div class="bg-img" style="background-image: url(' +
((__t = ( bannerPath )) == null ? '' : __t) +
');"></div>\r\n  <div class="container">\r\n    <div class="crumbs">\r\n      <a href="/">首页</a><span>/</span>\r\n      <a href="#">法律观察</a>\r\n    </div>\r\n    <div class="title">\r\n      <h1 class="cn-title per200"><span class="ani-z"><span class="ani-y">' +
((__t = ( cntitle )) == null ? '' : __t) +
'</span></span></h1>\r\n      <h2 class="en-title per200"><span class="ani-z"><span class="ani-y">' +
((__t = ( entitle )) == null ? '' : __t) +
'</span></span></h2>\r\n    </div>\r\n  </div>\r\n</section>';

}
return __p
}