module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!bannerPath) var bannerPath='';
if (!title) var title = '';
if (!notitle) var notitle = false;
;
__p += '\r\n<section class="news-banner ' +
((__t = ( notitle ? 'notitle':'' )) == null ? '' : __t) +
'">\r\n  <div class="banner-mask"></div>\r\n  <div class="bg-img" style="background-image: url(' +
((__t = ( bannerPath )) == null ? '' : __t) +
');"></div>\r\n  <div class="container">\r\n    <div class="cont">\r\n      <div class="crumbs">\r\n        <a href="/">首页</a><span>/</span>\r\n        <a href="#">文康动态</a><span>/</span>\r\n        <a href="#">文康党建</a>\r\n      </div>\r\n      <h1>\r\n        <div class="per200"><span class="ani-z"><span class="ani-y">' +
((__t = ( title )) == null ? '' : __t) +
'</span></span></div>\r\n      </h1>\r\n    </div>\r\n  </div>\r\n</section>';

}
return __p
}