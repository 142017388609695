import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'

//返回顶部
$('.footer .back-top').on('click',function(){
  $("html, body").animate({
    scrollTop: 0
  }, {
      duration: 500,
      easing: "swing"
  });
  return false;
})

//专业领域中的tab切换
var footerNavSwiper = new Swiper(".footer-nav .mySwiper", {
  allowTouchMove: false,
  speed: 400,
  // autoHeight: true,
  effect: 'fade',
  fadeEffect: {
    crossFade: true,
  },
  on:{
    init: function(swiper){
      $('.footer-nav .nav-item .tab span').eq(0).addClass('active')
    }, 
    transitionStart: function(swiper){
      $('.footer-nav .nav-item .tab span').eq(this.activeIndex).addClass('active').siblings().removeClass('active')
    },
  },
});

$('.footer-nav .nav-item .tab span').hover(function(){
  let index = $(this).index()
  footerNavSwiper.slideTo(index)
})

//talk表单隐藏显示
$('.talk-section .left').on('click',function(){
  $('.fixed-talk-form .mask').fadeIn(300)
  $('.fixed-talk-form .talk-form').fadeIn(300)
})
$('.fixed-talk-form .talk-form .close-btn,.fixed-talk-form .mask').on('click',function(){
  $('.fixed-talk-form .mask').fadeOut(300)
  $('.fixed-talk-form .talk-form').fadeOut(300)
})

//页面滚动监听
$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //滚动高度
  var viewH = $(window).height(); //可见高度
  var contentH = $(document).height(); //内容高度
  var footerTop = $('footer').offset().top

  if (scroH > footerTop - viewH * 5 / 6) {
    //距离顶部大于200px时
    $('footer .talk-section .bg-img').addClass('img-show')
  }else{
    $('footer .talk-section .bg-img').removeClass('img-show')
  }
});