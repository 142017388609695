module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<section class="loading">\r\n  <div class="cont">\r\n    <img src="' +
((__t = (require('../../assets/images/loading_img1.png'))) == null ? '' : __t) +
'" alt="">\r\n    <p>加载中</p>\r\n  </div>\r\n</section>';

}
return __p
}