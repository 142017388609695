import './jquery.nicescroll.min'

// if (window.devicePixelRatio < 2) {
//   if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
//      $('body').niceScroll({
//       mousescrollstep: 40,
//       scrollspeed: 80,
//       zindex: 101,
//       cursorcolor: '#BDBDBD',
//       cursorborder: '1px solid #BDBDBD',
//       cursorwidth: '5px',
//       cursoropacitymax: 0.6,
//       smoothscroll: true,
//       iframeautoresizeL: true,
//     });
//   }
// }
function resizeScroll () {
  // if (window.devicePixelRatio < 2) {
  //     if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
  //         setTimeout(() => {
  //             $('body').getNiceScroll().resize()
  //         }, 500);
  //     }
  // }
}


export {resizeScroll}